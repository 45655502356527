/**
 * This file is being used as reducer for CaptureOrder
 * @exports CaptureOrderData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  captureOrderSaved: false,
  captureOrderValidated: { status: false, singleCick: false },
  captureOrderSubmited: { status: false, singleCick: false },
  captureOrderDeleted: false,
  styleNumberFetched: false,
  styleNumberData: [],
  shipToUpdated: false,
  sizesData: [],
  shipToSuggestionData: [],
  captureOrderAllFilterData: [],
  viewOrderTemplateUploaded: false
};

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_CAPTURE_ORDER_ALL_FILTER_DATA: {
      return {
        ...state,
        captureOrderAllFilterData: action.payload
      };
    }
    case actionTypes.GET_CAPTURE_ORDER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_CAPTURE_ORDER_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_CAPTURE_VIEW_ORDER_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.SET_CAPTURE_ORDER_SAVE_STATUS: {
      return {
        ...state,
        captureOrderSaved: action.payload
      };
    }
    case actionTypes.SET_CAPTURE_ORDER_VALIDATION_STATUS: {
      return {
        ...state,
        captureOrderValidated: action.payload
      };
    }
    case actionTypes.SET_VIEW_ORDER_TEMPLATE_UPLOAD_STATUS: {
      return {
        ...state,
        viewOrderTemplateUploaded: action.payload
      };
    }
    case actionTypes.SET_CAPTURE_ORDER_SUBMIT_STATUS: {
      return {
        ...state,
        captureOrderSubmited: action.payload
      };
    }
    case actionTypes.SET_CAPTURE_ORDER_DELETE_STATUS: {
      return {
        ...state,
        captureOrderDeleted: action.payload
      };
    }
    case actionTypes.SET_SHIP_TO_UPDATE_STATUS: {
      return {
        ...state,
        shipToUpdated: action.payload
      };
    }
    case actionTypes.SET_STYLE_NUMBER_FETCHED_STATUS: {
      return {
        ...state,
        styleNumberFetched: action.payload
      };
    }
    case actionTypes.SET_STYLE_NUMBER_DATA: {
      return {
        ...state,
        styleNumberData: action.payload
      };
    }
    case actionTypes.SET_SHIP_TO_SUGGESTION_DATA: {
      return {
        ...state,
        shipToSuggestionData: action.payload
      };
    }
    case actionTypes.SET_SIZES_DATA: {
      return {
        ...state,
        sizesData: action.payload
      };
    }
    case actionTypes.RESET_CAPTURE_ORDER_DATA: {
      return {
        ...state,
        filterData: [],
        tableData: [],
        captureOrderSaved: false,
        captureOrderValidated: { status: false, singleCick: false },
        captureOrderSubmited: { status: false, singleCick: false },
        captureOrderDeleted: false,
        styleNumberFetched: false,
        styleNumberData: [],
        shipToUpdated: false,
        sizesData: [],
        shipToSuggestionData: [],
        captureOrderAllFilterData: [],
        viewOrderTemplateUploaded: false
      };
    }
    default:
      return state;
  }
};
