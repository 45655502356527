/**
 * This file is being used as reducer for SisterFCTYUpdate
 * @exports SisterFCTYUpdateData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  sisterFCTYAllFilterData: [],
  sisterFCTYUpdateSave: false,
  sisterFCTYUpdateAllSave: { status: false, singleCick: false }
};

const SisterFCTYUpdateData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_SISTER_FCTY_UPDATE_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_SISTER_FCTY_UPDATE_VIEW_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_SISTER_FCTY_UPDATE_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_SISTER_FCTY_UPDATE_ALL_FILTER_DATA: {
      return {
        ...state,
        sisterFCTYAllFilterData: action.payload
      };
    }
    case actionTypes.SET_SISTER_FCTY_UPDATE_SAVE_STATUS: {
      return {
        ...state,
        sisterFCTYUpdateSave: action.payload
      };
    }
    case actionTypes.SET_SISTER_FCTY_UPDATE_ALL_SAVE_STATUS: {
      return {
        ...state,
        sisterFCTYUpdateAllSave: action.payload
      };
    }
    case actionTypes.RESET_SISTER_FCTY_UPDATE_ALL_DATA: {
      return {
        ...state,
        filterData: [],
        tableData: [],
        sisterFCTYAllFilterData: [],
        sisterFCTYUpdateSave: false,
        sisterFCTYUpdateAllSave: { status: false, singleCick: false }
      };
    }
    default:
      return state;
  }
};

export default SisterFCTYUpdateData;
