/**
 * This file contains the actions for the SideMenuBar (LEFT NAV) functionality
 * @exports Actions for sideMenuBar functionality
 * @author {Cognizant Technology Solution}
 * @flow
 */
import { Dispatch } from 'redux';
import menuListOptions from '../constants/menuObject';
import * as actionTypes from './actiontype';

/**
  * This actions update the menu list of sideMenuBar with the payload.
  * @param {Object} payload menu list of the sideMenuBar.
  * @returns {action} of type FETCH_MENU_LIST
  * @dispatches to sideMenuBar reducer
  */
export const getMenuList = () => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.FETCH_MENU_LIST,
    payload: menuListOptions
  });
};

/**
  * Sets the currently active route
  * @param {string} payload the name of the route
  */
export const setActiveRoute = (payload: string) => ({
  type: actionTypes.SET_ACTIVE_ROUTE,
  payload
});
