/**
* Redux Container Component for NikeHeader
* @exports ConnectedComponent
* @author {Cognizant Technology Solutions}
* @flow
*/
import { connect, Dispatch } from 'react-redux';
import { getMenuList, setActiveRoute } from '../../actions/nikeHeaderAction';
import NikeHeader from '../../nikeCustomComponents/nikeHeader';

interface State {
  NikeHeader: Object;
}

export const mapStateToProps = (state: State) => ({
  menuListOptions: state.NikeHeader.menuListOptions,
  userDetails: state.Authorization.userDetails
});

export const mapsDispatchToProps = (dispatch: Dispatch) => ({
  getMenuList: () => dispatch(getMenuList()),
  setActiveRoute: () => dispatch(setActiveRoute('#'))
});

export default connect(mapStateToProps, mapsDispatchToProps)(NikeHeader);
