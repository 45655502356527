/**
 * This file is being used as reducer for MaterialExplosion
 * @exports MaterialExplosionData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  materialExplosionAllFilterData: [],
  isMaterialExploded: false,
  emailSent: false,
  tableData: []
};

const MaterialExplosionData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.SET_MATERIAL_EXPLODED_STATUS: {
      return {
        ...state,
        isMaterialExploded: action.payload
      };
    }
    case actionTypes.GET_MATERIAL_EXPLOSION_EMAIL_STATUS: {
      return {
        ...state,
        emailSent: action.payload
      };
    }
    case actionTypes.GET_MATERIAL_EXPLOSION_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_MATERIAL_EXPLOSION_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_MATERIAL_EXPLOSION_ALL_FILTER_DATA: {
      return {
        ...state,
        materialExplosionAllFilterData: action.payload
      };
    }
    case actionTypes.RESET_MATERIAL_EXPLOSION_DATA: {
      return {
        ...state,
        filterData: [],
        materialExplosionAllFilterData: [],
        isMaterialExploded: false,
        emailSent: false,
        tableData: []
      };
    }
    default:
      return state;
  }
};

export default MaterialExplosionData;
