/**
 * Nike application
 * @exports: App
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import NikeHeader from './containers/NikeHeader';
import routes from './routes';
import './App.scss';

function App() {
  return (
    <div className="ncss-container rootContainer">
      <div className="ncss-row rootContainerBody">
        <BrowserRouter>
          <div className="full rootContainerHeader">
            <ErrorBoundary><NikeHeader /></ErrorBoundary>
          </div>
          <Switch>
            {routes.map((route) => (<Route key={route.name} {...route} />))}
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
