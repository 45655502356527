/**
 * This file is used for listing out all actions.
 * @exports ActionType for actions
 * @author {Cognizant Technology Solutions}
 */
export const SET_REQUEST_ERROR = 'SET_REQUEST_ERROR';
export const SET_REQUEST_ERROR_CODE_TEXT = 'SET_REQUEST_ERROR_CODE_TEXT';
export const OPEN_SIDEMENUBAR = 'OPEN_SIDEMENUBAR';
export const CLOSE_SIDEMENUBAR = 'CLOSE_SIDEMENUBAR';
export const FETCH_MENU_LIST = 'FETCH_MENU_LIST';
export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const GET_SPINNER = 'GET_SPINNER';
export const SET_SPINNER = 'SET_SPINNER';
export const SET_MASS_UPDATE_STATUS = 'SET_MASS_UPDATE_STATUS';
export const SET_SHIP_TO_UPDATE_STATUS = 'SET_SHIP_TO_UPDATE_STATUS';
export const SET_STYLE_NUMBER_FETCHED_STATUS = 'SET_STYLE_NUMBER_FETCHED_STATUS';
export const SET_STYLE_NUMBER_DATA = 'SET_STYLE_NUMBER_DATA';
export const SET_SHIP_TO_SUGGESTION_DATA = 'SET_SHIP_TO_SUGGESTION_DATA';
export const SET_SIZES_DATA = 'SET_SIZES_DATA';
export const RESET_CAPTURE_ORDER_DATA = 'RESET_CAPTURE_ORDER_DATA';
export const RESET_VIEW_ORDER_DATA = 'RESET_VIEW_ORDER_DATA';

/** Authorization */
export const SMX_AUTH = 'SMX_AUTH';

/** Dashboard */
export const GET_SEASON_ORDER_DATA = 'GET_SEASON_ORDER_DATA';
export const GET_SDR_DATA = 'GET_SDR_DATA';
export const USER_DETAILS = 'USER_DETAILS';

/** Profile */
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const USER_DETAILS_PROFILE_DATA = 'USER_DETAILS_PROFILE_DATA';
/** GgpOrder */
export const GET_GGP_ORDER_DATA = 'GET_GGP_ORDER_DATA';
export const GGP_ORDER_SPINNER = 'GGP_ORDER_SPINNER';
export const USER_DETAILS_GGP_ORDER = 'USER_DETAILS_GGP_ORDER';
export const GET_GGP_ORDER_FILTER_DATA = 'GET_GGP_ORDER_FILTER_DATA';
export const GET_GGP_ORDER_TABLE_DATA = 'GET_GGP_ORDER_TABLE_DATA';
export const SET_GGP_ORDER_UPLOAD_STATUS = 'SET_GGP_ORDER_UPLOAD_STATUS';
export const SET_GGP_ORDER_SUMBIT_STATUS = 'SET_GGP_ORDER_SUMBIT_STATUS';
export const SET_GGP_ORDER_DELETE_STATUS = 'SET_GGP_ORDER_DELETE_STATUS';
export const SET_GGP_ORDER_VALIDATION_STATUS = 'SET_GGP_ORDER_VALIDATION_STATUS';
export const GET_GGP_ORDER_ALL_FILTER_DATA = 'GET_GGP_ORDER_ALL_FILTER_DATA';
export const RESET_GGP_ORDER_ALL_DATA = 'RESET_GGP_ORDER_ALL_DATA';
export const SET_GGP_ORDER_UPLOAD_ERROR_MESSAGE = 'SET_GGP_ORDER_UPLOAD_ERROR_MESSAGE';

/** AssignSource */
export const GET_ASSIGN_SOURCE_DATA = 'GET_ASSIGN_SOURCE_DATA';
export const ASSIGN_SOURCE_SPINNER = 'ASSIGN_SOURCE_SPINNER';

/** CaptureOrder */
export const GET_CAPTURE_ORDER_DATA = 'GET_CAPTURE_ORDER_DATA';
export const CAPTURE_ORDER_SPINNER = 'CAPTURE_ORDER_SPINNER';
export const USER_DETAILS_CAPTURE_ORDER = 'USER_DETAILS_CAPTURE_ORDER';
export const GET_CAPTURE_ORDER_FILTER_DATA = 'GET_CAPTURE_ORDER_FILTER_DATA';
export const GET_CAPTURE_ORDER_TABLE_DATA = 'GET_CAPTURE_ORDER_TABLE_DATA';
export const GET_CAPTURE_VIEW_ORDER_TABLE_DATA = 'GET_CAPTURE_VIEW_ORDER_TABLE_DATA';
export const SET_CAPTURE_ORDER_SAVE_STATUS = 'SET_CAPTURE_ORDER_SAVE_STATUS';
export const SET_CAPTURE_ORDER_VALIDATION_STATUS = 'SET_CAPTURE_ORDER_VALIDATION_STATUS';
export const SET_CAPTURE_ORDER_SUBMIT_STATUS = 'SET_CAPTURE_ORDER_SUBMIT_STATUS';
export const SET_CAPTURE_ORDER_DELETE_STATUS = 'SET_CAPTURE_ORDER_DELETE_STATUS';
export const GET_CAPTURE_ORDER_ALL_FILTER_DATA = 'GET_CAPTURE_ORDER_ALL_FILTER_DATA';

/** ViewOrder */
export const GET_VIEW_ORDER_DATA = 'GET_VIEW_ORDER_DATA';
export const VIEW_ORDER_SPINNER = 'VIEW_ORDER_SPINNER';
export const USER_DETAILS_VIEW_ORDER = 'USER_DETAILS_VIEW_ORDER';
export const GET_VIEW_ORDER_FILTER_DATA = 'GET_VIEW_ORDER_FILTER_DATA';
export const GET_VIEW_ORDER_TABLE_DATA = 'GET_VIEW_ORDER_TABLE_DATA';
export const SET_VIEW_ORDER_TEMPLATE_UPLOAD_STATUS = 'SET_VIEW_ORDER_TEMPLATE_UPLOAD_STATUS';

/** UploadOrder */
export const GET_UPLOAD_ORDER_DATA = 'GET_UPLOAD_ORDER_DATA';
export const UPLOAD_ORDER_SPINNER = 'UPLOAD_ORDER_SPINNER';
export const USER_DETAILS_UPLOAD_ORDER = 'USER_DETAILS_UPLOAD_ORDER';
export const SET__UPLOAD_ERROR_MESSAGE = 'SET_UPLOAD_ERROR_MESSAGE';

/** GeoOrder */
export const GET_GEO_ORDER_DATA = 'GET_GEO_ORDER_DATA';
export const GEO_ORDER_SPINNER = 'GEO_ORDER_SPINNER';
export const USER_DETAILS_GEO_ORDER = 'USER_DETAILS_GEO_ORDER';
export const GET_GEO_ORDER_FILTER_DATA = 'GET_GEO_ORDER_FILTER_DATA';
export const GET_GEO_ORDER_TABLE_DATA = 'GET_GEO_ORDER_TABLE_DATA';
export const GET_GEO_VIEW_ORDER_TABLE_DATA = 'GET_GEO_VIEW_ORDER_TABLE_DATA';
export const SET_GEO_ORDER_UPLOAD_STATUS = 'SET_GEO_ORDER_UPLOAD_STATUS';
export const SET_GEO_ORDER_SAVE_STATUS = 'SET_GEO_ORDER_SAVE_STATUS';
export const SET_GEO_ORDER_DELETE_STATUS = 'SET_GEO_ORDER_DELETE_STATUS';
export const SET_GEO_ORDER_VALIDATION_STATUS = 'SET_GEO_ORDER_VALIDATION_STATUS';
export const SET_GEO_TEMPLATE_UPLOAD_STATUS = 'SET_GEO_TEMPLATE_UPLOAD_STATUS';
export const GET_GEO_ORDER_ALL_FILTER_DATA = 'GET_GEO_ORDER_ALL_FILTER_DATA';
export const RESET_GEO_ORDER_ALL_DATA = 'RESET_GEO_ORDER_ALL_DATA';
export const SET_GEO_ORDER_UPLOAD_ERROR_MESSAGE = 'SET_GEO_ORDER_UPLOAD_ERROR_MESSAGE';

/** MaterialExplosion */
export const GET_MATERIAL_EXPLOSION_DATA = 'GET_MATERIAL_EXPLOSION_DATA';
export const MATERIAL_EXPLOSION_SPINNER = 'MATERIAL_EXPLOSION_SPINNER';

export const GET_MATERIAL_EXPLOSION_FILTER_DATA = 'GET_MATERIAL_EXPLOSION_FILTER_DATA';
export const GET_MATERIAL_EXPLOSION_ALL_FILTER_DATA = 'GET_MATERIAL_EXPLOSION_ALL_FILTER_DATA';
export const GET_MATERIAL_EXPLOSION_TABLE_DATA = 'GET_MATERIAL_EXPLOSION_TABLE_DATA';
export const SET_MATERIAL_EXPLODED_STATUS = 'SET_MATERIAL_EXPLODED_STATUS';
export const GET_MATERIAL_EXPLOSION_EMAIL_STATUS = 'GET_MATERIAL_EXPLOSION_EMAIL_STATUS';
export const RESET_MATERIAL_EXPLOSION_DATA = 'RESET_MATERIAL_EXPLOSION_DATA';
/** ViewMaterialExplosion */
export const GET_VIEW_MATERIAL_EXPLOSION_DATA = 'GET_VIEW_MATERIAL_EXPLOSION_DATA';
export const VIEW_MATERIAL_EXPLOSION_SPINNER = 'VIEW_MATERIAL_EXPLOSION_SPINNER';
export const GET_VIEW_MATERIAL_EXPLOSION_FILTER_DATA = 'GET_VIEW_MATERIAL_EXPLOSION_FILTER_DATA';
export const GET_VIEW_MATERIAL_EXPLOSION_TABLE_DATA = 'GET_VIEW_MATERIAL_EXPLOSION_TABLE_DATA';
export const GET_VIEW_MATERIAL_EXPLOSION_ALL_FILTER_DATA = 'GET_VIEW_MATERIAL_EXPLOSION_ALL_FILTER_DATA';
export const RESET_VIEW_MATERIAL_EXPLOSION_DATA = 'RESET_VIEW_MATERIAL_EXPLOSION_DATA';

/** ViewMaterialExplosion */
export const GET_VIEW_SS_PO_FILTER_DATA = 'GET_VIEW_SS_PO_FILTER_DATA';
export const GET_VIEW_SS_PO_TABLE_DATA = 'GET_VIEW_SS_PO_TABLE_DATA';
export const GET_VIEW_SS_PO_ALL_FILTER_DATA = 'GET_VIEW_SS_PO_ALL_FILTER_DATA';
export const RESET_VIEW_SS_PO_DATA = 'RESET_VIEW_SS_PO_DATA';

/** UpdateTable */
export const GET_UPDATE_TABLE_DATA = 'GET_UPDATE_TABLE_DATA';
export const SET_UPDATE_STATUS_UPDATE_TABLE = 'SET_UPDATE_STATUS_UPDATE_TABLE';
export const RESET_UPDATE_TABLE_ALL_DATA = 'RESET_UPDATE_TABLE_ALL_DATA';
export const GET_DESTINATION_MASTER_FILTER_DATA = 'GET_DESTINATION_MASTER_FILTER_DATA';
export const GET_DESTINATION_MASTER_ALL_FILTER_DATA = 'GET_DESTINATION_MASTER_ALL_FILTER_DATA';

/** Order Status Report */
export const GET_ORDER_STATUS_REPORT_DATA = 'GET_ORDER_STATUS_REPORT_DATA';
export const ORDER_STATUS_REPORT_SPINNER = 'ORDER_STATUS_REPORT_SPINNER';
export const USER_DETAILS_ORDER_STATUS_REPORT = 'USER_DETAILS_ORDER_STATUS_REPORT';
export const GET_FILTER_DATA = 'GET_FILTER_DATA';
export const GET_TABLE_DATA = 'GET_TABLE_DATA';

/** Size Check Report */

export const GET_SIZE_CHECK_REPORT_DATA = 'GET_SIZE_CHECK_REPORT_DATA';
export const SIZE_CHECK_REPORT_SPINNER = 'SIZE_CHECK_REPORT_SPINNER';
export const USER_DETAILS_SIZE_CHECK_REPORT = 'USER_DETAILS_SIZE_CHECK_REPORT';
export const GET_SIZE_CHECK_REPORT_FILTER_DATA = 'GET_SIZE_CHECK_REPORT_FILTER_DATA';
export const GET_SIZE_CHECK_REPORT_TABLE_DATA = 'GET_SIZE_CHECK_REPORT_TABLE_DATA';

/** ShareOrder */
export const GET_SHARE_ORDER_DATA = 'GET_SHARE_ORDER_DATA';
export const SHARE_ORDER_SPINNER = 'SHARE_ORDER_SPINNER';
export const USER_DETAILS_SHARE_ORDER = 'USER_DETAILS_SHARE_ORDER';
export const GET_SHARE_ORDER_FILTER_DATA = 'GET_SHARE_ORDER_FILTER_DATA';
export const GET_SHARE_ORDER_TABLE_DATA = 'GET_SHARE_ORDER_TABLE_DATA';
export const GET_SHARE_ORDER_ALL_FILTER_DATA = 'GET_SHARE_ORDER_ALL_FILTER_DATA';
export const GET_SHARE_ORDER_EMAIL_STATUS = 'GET_SHARE_ORDER_EMAIL_STATUS';
export const GET_SHARE_ORDER_ALL_EMAIL_STATUS = 'GET_SHARE_ORDER_ALL_EMAIL_STATUS';
export const RESET_SHARE_ORDER_DATA = 'RESET_SHARE_ORDER_DATA';

/** SisterFCTYUpdate */
export const GET_SISTER_FCTY_UPDATE_DATA = 'GET_SISTER_FCTY_UPDATE_DATA';
export const SISTER_FCTY_UPDATE_SPINNER = 'SISTER_FCTY_UPDATE_SPINNER';
export const USER_DETAILS_SISTER_FCTY_UPDATE = 'USER_DETAILS_SISTER_FCTY_UPDATE';
export const GET_SISTER_FCTY_UPDATE_FILTER_DATA = 'GET_SISTER_FCTY_UPDATE_FILTER_DATA';
export const GET_SISTER_FCTY_UPDATE_TABLE_DATA = 'GET_SISTER_FCTY_UPDATE_TABLE_DATA';
export const GET_SISTER_FCTY_UPDATE_VIEW_TABLE_DATA = 'GET_SISTER_FCTY_UPDATE_VIEW_TABLE_DATA';
export const GET_SISTER_FCTY_UPDATE_ALL_FILTER_DATA = 'GET_SISTER_FCTY_UPDATE_ALL_FILTER_DATA';
export const SET_SISTER_FCTY_UPDATE_ALL_SAVE_STATUS = 'SET_SISTER_FCTY_UPDATE_ALL_SAVE_STATUS';
export const SET_SISTER_FCTY_UPDATE_SAVE_STATUS = 'SET_SISTER_FCTY_UPDATE_SAVE_STATUS';
export const RESET_SISTER_FCTY_UPDATE_ALL_DATA = 'RESET_SISTER_FCTY_UPDATE_ALL_DATA';

/** SubmitToSAP */
export const GET_SUBMIT_TO_SAP_DATA = 'GET_SUBMIT_TO_SAP_DATA';
export const GET_SUBMIT_TO_SAP_TABLE_DATA = 'GET_SUBMIT_TO_SAP_TABLE_DATA';
export const GET_VIEW_SUBMIT_TO_SAP_TABLE_DATA = 'GET_VIEW_SUBMIT_TO_SAP_TABLE_DATA';
export const SUBMIT_TO_SAP_SPINNER = 'SUBMIT_TO_SAP_SPINNER';
export const GET_SUBMIT_TO_SAP_FILTER_DATA = 'GET_SUBMIT_TO_SAP_FILTER_DATA';
export const GET_SUBMIT_TO_SAP_ALL_FILTER_DATA = 'GET_SUBMIT_TO_SAP_ALL_FILTER_DATA';
export const SET_SUBMIT_TO_SAP_VALIDATION_STATUS = 'SET_SUBMIT_TO_SAP_VALIDATION_STATUS';
export const SET_SUBMIT_TO_SAP_SUBMIT_STATUS = 'SET_SUBMIT_TO_SAP_SUBMIT_STATUS';
export const SET_SUBMIT_TO_SAP_TEMPLATE_UPLOAD_STATUS = 'SET_SUBMIT_TO_SAP_TEMPLATE_UPLOAD_STATUS';
export const RESET_SUBMIT_TO_SAP_ALL_DATA = 'RESET_SUBMIT_TO_SAP_ALL_DATA';

/** ValidateFOB */
export const GET_VALIDATE_FOB_DATA = 'GET_VALIDATE_FOB_DATA';
export const VALIDATE_FOB_DATA_SPINNER = 'VALIDATE_FOB_DATA_SPINNER';
export const GET_VALIDATE_FOB_TABLE_DATA = 'GET_VALIDATE_FOB_TABLE_DATA';
export const GET_VALIDATE_FOB_FILTER_DATA = 'GET_VALIDATE_FOB_FILTER_DATA';
export const GET_VALIDATE_FOB_ALL_FILTER_DATA = 'GET_VALIDATE_FOB_ALL_FILTER_DATA';
export const SET_VALIDATE_FOB_VALIDATION_STATUS = 'SET_VALIDATE_FOB_VALIDATION_STATUS';
export const SET_VALIDATE_FOB_SAVE_STATUS = 'SET_VALIDATE_FOB_SAVE_STATUS';
export const SET_VALIDATE_FOB_EMAIL_STATUS = 'SET_VALIDATE_FOB_EMAIL_STATUS';
export const RESET_VALIDATE_FOB_ALL_DATA = 'RESET_VALIDATE_FOB_ALL_DATA';

/** Header */
export const USER_DETAILS_HEADER = 'USER_DETAILS_HEADER';
