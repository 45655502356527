/**
 * Routes
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import React, { Suspense } from 'react';
import NikeSpinner from './nikeCustomComponents/nikeSpinner';

const Dashboard = React.lazy(() => import('./containers/Dashboard'));
const GgpOrder = React.lazy(() => import('./containers/GgpOrder'));
const ViewGgpOrder = React.lazy(() => import('./containers/ViewGgpOrder'));
const CaptureOrder = React.lazy(() => import('./containers/CaptureOrder'));
const GeoOrder = React.lazy(() => import('./containers/GeoOrder'));
const MaterialExplosion = React.lazy(() => import('./containers/MaterialExplosion'));
const UpdateTable = React.lazy(() => import('./containers/UpdateTable'));
const OrderStatusReport = React.lazy(() => import('./containers/OrderStatusReport'));
const SizeCheckReport = React.lazy(() => import('./containers/SizeCheckReport'));
const ShareOrder = React.lazy(() => import('./containers/ShareOrder'));
const SisterFCTYUpdate = React.lazy(() => import('./containers/SisterFCTYUpdate'));
const SubmitToSAP = React.lazy(() => import('./containers/SubmitToSAP'));
const ValidateFOB = React.lazy(() => import('./containers/ValidateFOB'));
const ViewMaterialExplosion = React.lazy(() => import('./containers/ViewMaterialExplosion'));
const ViewOrder = React.lazy(() => import('./containers/ViewOrder'));
const UploadOrder = React.lazy(() => import('./containers/UploadOrder'));
const ErrorPage = React.lazy(() => import('./pages/ErrorPage'));
const ViewSSPO = React.lazy(() => import('./containers/ViewSSPO'));

const getLazyComponent = (Component: Object) => (props: Object) => (
  <Suspense fallback={(
    <NikeSpinner open className="lazyPageSpinner">
      <p className="lazydesc spinner-text">Please wait while the page loads ...</p>
    </NikeSpinner>
  )}
  >
    <Component {...props} />
  </Suspense>
);

export default [
  {
    name: 'Dashboard',
    path: '/',
    exact: true,
    component: getLazyComponent(Dashboard)
  },
  {
    name: 'GGP Order',
    path: '/ggporder/',
    exact: true,
    component: getLazyComponent(GgpOrder)
  },
  {
    name: 'View/Update GGP Order',
    path: '/viewggporder/',
    exact: true,
    component: getLazyComponent(ViewGgpOrder)
  },
  {
    name: 'View/Update GGP Order',
    path: '/validatedggporder/',
    exact: true,
    component: getLazyComponent(ViewGgpOrder)
  },
  {
    name: 'Capture Order',
    path: '/captureorder/',
    exact: true,
    component: getLazyComponent(CaptureOrder)
  },
  {
    name: 'Capture Order',
    path: '/validatedcaptureorder/',
    exact: true,
    component: getLazyComponent(ViewOrder)
  },
  {
    name: 'Upload Order',
    path: '/uploadorder/',
    exact: true,
    component: getLazyComponent(UploadOrder)
  },
  {
    name: 'View/Update/Download Order',
    path: '/vieworder/',
    exact: true,
    component: getLazyComponent(ViewOrder)
  },
  {
    name: 'GEO Order',
    path: '/geoorder/',
    exact: true,
    component: getLazyComponent(GeoOrder)
  },
  {
    name: 'GEO Order',
    path: '/validatedgeoorder/',
    exact: true,
    component: getLazyComponent(GeoOrder)
  },
  {
    name: 'View/Update GEO Order',
    path: '/viewgeoorder/',
    exact: true,
    component: getLazyComponent(GeoOrder)
  },
  {
    name: 'Material Explosion',
    path: '/materialexplosion/',
    exact: true,
    component: getLazyComponent(MaterialExplosion)
  },
  {
    name: 'Material Explosion',
    path: '/viewmaterialexploded/',
    exact: true,
    component: getLazyComponent(MaterialExplosion)
  },
  {
    name: 'View Material Explosion',
    path: '/viewmaterialexplosion/',
    exact: true,
    component: getLazyComponent(ViewMaterialExplosion)
  },
  {
    name: 'Update Table',
    path: '/updatetable/',
    exact: true,
    component: getLazyComponent(UpdateTable)
  },
  {
    name: 'Order Status Report',
    path: '/orderstatusreport/',
    exact: true,
    component: getLazyComponent(OrderStatusReport)
  },
  {
    name: 'Size Check Report',
    path: '/sizecheckreport/',
    exact: true,
    component: getLazyComponent(SizeCheckReport)
  },
  {
    name: 'Share Order',
    path: '/shareorder/',
    exact: true,
    component: getLazyComponent(ShareOrder)
  },
  {
    name: 'Sister FCTY Update',
    path: '/sisterfctyupdate/',
    exact: true,
    component: getLazyComponent(SisterFCTYUpdate)
  },
  {
    name: 'Submit To SAP',
    path: '/submittosap/',
    exact: true,
    component: getLazyComponent(SubmitToSAP)
  },
  {
    name: 'Submit To SAP',
    path: '/validatedsubmittosap/',
    exact: true,
    component: getLazyComponent(SubmitToSAP)
  },
  {
    name: 'View Submit To SAP',
    path: '/viewsubmittosap/',
    exact: true,
    component: getLazyComponent(SubmitToSAP)
  },
  {
    name: 'View Samples PO',
    path: '/viewSSPO/',
    exact: true,
    component: getLazyComponent(ViewSSPO)
  },
  {
    name: 'Validate FOB',
    path: '/validatefob/',
    exact: true,
    component: getLazyComponent(ValidateFOB)
  },
  {
    name: 'Validate FOB',
    path: '/validatedfob/',
    exact: true,
    component: getLazyComponent(ValidateFOB)
  },
  {
    name: 'Error Page',
    path: '/error/',
    exact: true,
    component: getLazyComponent(ErrorPage)
  }
];
