/**
 * Combines all reducers into a single reducer
 * @exports CombinedReducers
 * @flow
 */
import { combineReducers } from 'redux';
import Authorization from './authReducer';
import NikeHeader from './nikeHeaderReducer';
import GgpOrderData from './ggpOrderReducer';
import CaptureOrderData from './captureOrderReducer';
import GeoOrderData from './geoOrderReducer';
import MaterialExplosionData from './materialExplosionReducer';
import UpdateTableData from './updateTableReducer';
import SizeCheckReportData from './sizeCheckReportReducer';
import OrderStatusReportData from './orderStatusReportReducer';
import ShareOrderData from './shareOrderReducer';
import SisterFCTYUpdateData from './sisterFCTYUpdateReducer';
import SubmitToSAPData from './submitToSAPReducer';
import ValidateFOBData from './validateFOBReducer';
import ViewMaterialExplosionData from './viewMaterialExplosionReducer';
import ViewOrderData from './viewOrderReducer';
import ViewSSPOData from './viewSSPOReducer';
import CommonData from './commonReducer';

export default combineReducers({
  Authorization,
  NikeHeader,
  GgpOrderData,
  CaptureOrderData,
  GeoOrderData,
  MaterialExplosionData,
  UpdateTableData,
  OrderStatusReportData,
  SizeCheckReportData,
  ShareOrderData,
  SisterFCTYUpdateData,
  SubmitToSAPData,
  ValidateFOBData,
  ViewMaterialExplosionData,
  ViewOrderData,
  ViewSSPOData,
  CommonData
});
