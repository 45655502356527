/**
 * This file is being used as reducer for SSPO
 * @exports viewSSPOData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  viewSSPOAllFilterData: []
};

const ViewSSPOData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_VIEW_SS_PO_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_VIEW_SS_PO_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_VIEW_SS_PO_ALL_FILTER_DATA: {
      return {
        ...state,
        viewSSPOAllFilterData: action.payload
      };
    }
    case actionTypes.RESET_VIEW_SS_PO_DATA: {
      return {
        ...state,
        filterData: [],
        tableData: [],
        viewSSPOAllFilterData: []
      };
    }

    default:
      return state;
  }
};

export default ViewSSPOData;
