import appConfig from '../appConfig';

/**
 * This file is used for exporting Menu details.
 * @author {Cognizant Technology Solution}
 */
export default [
  {
    name: 'Build Non-Sales order',
    relatedLinks: [
      '/captureorder',
      '/uploadorder'
    ],
    subMenu: [
      {
        link: '/captureorder/',
        name: 'capture order'
      },
      {
        link: '/uploadorder/',
        name: 'upload order'
      },
      {
        link: '/vieworder/',
        name: 'view/update/Download order'
      }
    ],
    group: appConfig.nonSalesOrderGroups
  },
  {
    name: 'manage order',
    relatedLinks: [
      '/geoorder',
      '/ggporder'
    ],
    subMenu: [
      {
        link: '/geoorder/',
        name: 'GEO Order'
      },
      {
        link: '/viewgeoorder/',
        name: 'view/update GEO Order'
      }
    ],
    group: appConfig.manageOrderGroups
  },
  {
    name: 'create order',
    relatedLinks: [
      '/materialexplosion',
      '/sisterfctyupdate'
    ],
    subMenu: [
      {
        link: '/materialexplosion/',
        name: 'material explosion'
      },
      {
        link: '/viewmaterialexplosion/',
        name: 'view material explosion'
      },
      {
        link: '/sisterfctyupdate/',
        name: 'sister factory update'
      }
    ],
    group: appConfig.createOrderGroup
  },
  {
    name: 'publish order',
    relatedLinks: [
      '/shareorder',
      '/validatefob',
      '/submittosap'
    ],
    subMenu: [
      {
        link: '/shareorder/',
        name: 'share order'
      },
      // nsaha:05-18-23: Validate FOB will be under Publish Order instead of Manage Order
      {
        link: '/validatefob/',
        name: 'Validate FOB'
      },
      {
        link: '/submittosap/',
        name: 'submit to SAP'
      },
      {
        link: '/viewsubmittosap/',
        name: 'view/update submit to SAP'
      },
      {
        link: '/viewSSPO/',
        name: 'View Samples PO'
      }
    ],
    group: appConfig.publishOrderGroups
  },
  // {
  //   name: 'reports',
  //   relatedLinks: [
  //     '/orderstatusreport',
  //     '/sizecheckreport'
  //   ],
  //   subMenu: [
  //     {
  //       link: '/orderstatusreport/',
  //       name: 'order status report'
  //     },
  //     {
  //       link: '/sizecheckreport/',
  //       name: 'size check report'
  //     }
  //   ],
  //   group: appConfig.reportsGroups
  // },
  {
    name: 'Manual Table',
    relatedLinks: [
      '/updatetable'
    ],
    subMenu: [
      {
        link: '/updatetable/',
        name: 'Update Manual Table'
      }
    ],
    group: appConfig.manualTableGroups
  }
];
