/**
 * This file is being used as reducer for GgpOrderData
 * @exports GgpOrderData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  ggpOrderSubmited: { status: false, singleCick: false },
  ggpOrderDeleted: false,
  ggpOrderValidated: { status: false, singleCick: false },
  ggpOrderUploaded: {},
  uploadErrorMessage: '',
  ggpOrderAllFilterData: []
};

const GgpOrderData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_GGP_ORDER_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_GGP_ORDER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.SET_GGP_ORDER_SUMBIT_STATUS: {
      return {
        ...state,
        ggpOrderSubmited: action.payload
      };
    }
    case actionTypes.SET_GGP_ORDER_UPLOAD_ERROR_MESSAGE: {
      return {
        ...state,
        uploadErrorMessage: action.payload
      };
    }
    case actionTypes.SET_GGP_ORDER_DELETE_STATUS: {
      return {
        ...state,
        ggpOrderDeleted: action.payload
      };
    }
    case actionTypes.SET_GGP_ORDER_UPLOAD_STATUS: {
      return {
        ...state,
        ggpOrderUploaded: action.payload
      };
    }
    case actionTypes.GET_GGP_ORDER_ALL_FILTER_DATA: {
      return {
        ...state,
        ggpOrderAllFilterData: action.payload
      };
    }
    case actionTypes.SET_GGP_ORDER_VALIDATION_STATUS: {
      return {
        ...state,
        ggpOrderValidated: action.payload
      };
    }
    case actionTypes.RESET_GGP_ORDER_ALL_DATA: {
      return {
        ...state,
        userDetails: {},
        filterData: [],
        tableData: [],
        ggpOrderSubmited: { status: false, singleCick: false },
        ggpOrderDeleted: false,
        ggpOrderValidated: { status: false, singleCick: false },
        ggpOrderUploaded: false,
        uploadErrorMessage: '',
        ggpOrderAllFilterData: []
      };
    }
    default:
      return state;
  }
};

export default GgpOrderData;
