/**
 * Store
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import monitorReducersEnhancer from './reducers/enhancers/monitorReducerEnhancer';
import loggerMiddleware from './middleware/logger';
import rootReducer from './reducers';

const middlewares = [loggerMiddleware, thunkMiddleware];
const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));
const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
const composedEnhancers = compose(...enhancers);
const store = createStore(rootReducer, {}, composedEnhancers);

export default store;
