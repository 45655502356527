/**
 * Reducer for NikeHeader
 * @author {Cognizant Technology Solutions}
 * @flow
 */

import {
  FETCH_MENU_LIST
} from '../actions/actiontype';

const initialState = {
  menuListOptions: []
};

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case FETCH_MENU_LIST:
      return { ...state, menuListOptions: action.payload };
    default:
      return state;
  }
};
