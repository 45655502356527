/**
 * This file is used for storing the Images and its path.
 * @exports imagesPath
 * @author {Cognizant Technology Solution}
 */
import errorPage from './images/errorPage.png';
import spinner from './images/spinner.svg';
import smxLogo from './images/smx_logo.svg';
import smxLogoWhite from './images/smx_logo_white.svg';
import smxLogoBlack from './images/smx_logo_black.svg';
import smxBanner from './images/banner.jpg';
import OrderStatus from './images/OrderStatus.png';
import CaptureOrder from './images/CaptureOrder.png';
import ViewOrder from './images/ViewOrder.png';
import AlertIcon from './images/Alert.png';
import ArrowUp from './images/ArrowUp.png';
import Calendar from './images/Calendar.png';
import checkBlack from './images/Check_black.png';
import checkGreen from './images/Check_green.png';
import checkGreenBold from './images/success_check.png';
import Close from './images/Close.png';
import Time from './images/Time.png';
import user from './images/user.png';
import ProgramsIcon from './images/Programs.png';
import Nike from './images/nike.png';
import edit from './images/edit.png';
import Replay from './images/Replay.png';
import SaveBlack from './images/Save_black.png';
import Search from './images/Search.png';
import Upload from './images/Upload.png';
import Download from './images/Download.png';
import Whiteboard from './images/Whiteboard.png';
import Filter from './images/Filter.png';
import CloseGrey from './images/Close_Grey.png';
import UploadFail from './images/Upload_failed.png';
import CalendarGrey from './images/CalendarGrey.png';
import CaretLeft from './images/Caret_Left.png';
import CaretRight from './images/Caret_Right.png';
import NotFound from './images/notFoundImage.png';
import move from './images/move.png';
import successCheck from './images/successCheck.png';
import AlertRed from './images/Alert_Circle.png';
import ArrowUpBlue from './images/ArrowUpBlue.png';

const imagesPath = {
  smxLogo: {
    src: smxLogo,
    alt: 'SMX Logo'
  },
  nikeLogo: {
    src: Nike,
    alt: 'Nike'
  },
  caretLeft: {
    src: CaretLeft,
    alt: 'Caret Left'
  },
  caretRight: {
    src: CaretRight,
    alt: 'Caret Right'
  },
  moveIcon: {
    src: move,
    alt: 'Move Icon'
  },
  editIcon: {
    src: edit,
    alt: 'Edit Icon'
  },
  filterIcon: {
    src: Filter,
    alt: 'Filter Icon'
  },
  replayIcon: {
    src: Replay,
    alt: 'Reply Icon'
  },
  saveBlack: {
    src: SaveBlack,
    alt: 'Save Black'
  },
  searchIcon: {
    src: Search,
    alt: 'Search Icon'
  },
  uploadIcon: {
    src: Upload,
    alt: 'Upload Icon'
  },
  downloadIcon: {
    src: Download,
    alt: 'Download Icon'
  },
  whiteboardIcon: {
    src: Whiteboard,
    alt: 'Whiteboard Icon'
  },
  closeGrey: {
    src: CloseGrey,
    alt: 'CLose Grey Icon'
  },
  checkBlack: {
    src: checkBlack,
    alt: 'Check Black'
  },
  checkGreen: {
    src: checkGreen,
    alt: 'Check Green'
  },
  checkGreenBold: {
    src: checkGreenBold,
    alt: 'Check Green Bold'
  },
  closeIcon: {
    src: Close,
    alt: 'Close Icon'
  },
  timeIcon: {
    src: Time,
    alt: 'Time'
  },
  userIcon: {
    src: user,
    alt: 'user'
  },
  programsIcon: {
    src: ProgramsIcon,
    alt: 'Programs Icon'
  },
  calendarIcon: {
    src: Calendar,
    alt: 'Calendar'
  },
  arrowUpIcon: {
    src: ArrowUp,
    alt: 'Arrow Up'
  },
  alertIcon: {
    src: AlertIcon,
    alt: 'Alert Icon'
  },
  smxLogoWhite: {
    src: smxLogoWhite,
    alt: 'SMX Logo'
  },
  smxLogoBlack: {
    src: smxLogoBlack,
    alt: 'SMX Logo'
  },
  smxBanner: {
    src: smxBanner,
    alt: 'SMX Banner'
  },
  imgErrorPage: {
    src: errorPage,
    alt: 'ErrorImage'
  },
  spinner: {
    src: spinner,
    alt: 'spinner'
  },
  OrderStatus: {
    src: OrderStatus,
    alt: 'Order Status'
  },
  CaptureOrder: {
    src: CaptureOrder,
    alt: 'Capture Order'
  },
  ViewOrder: {
    src: ViewOrder,
    alt: 'View Order'
  },
  UploadFail: {
    src: UploadFail,
    alt: 'Upload Fail'
  },
  CalendarGrey: {
    src: CalendarGrey,
    alt: 'Calendar'
  },
  successCheck: {
    src: successCheck,
    alt: 'Calendar'
  },
  arrowUpBlue: {
    src: ArrowUpBlue,
    alt: 'Arrow Up Blue'
  },
  AlertRed: {
    src: AlertRed,
    alt: 'Alert Red'
  },
  NotFound: {
    src: NotFound,
    alt: 'Something went wrong'
  }
};

export default imagesPath;
