/**
 * This file is used for the actions for Authentication.
 * @exports Actions
 * @author {Cognizant Technology Solution}
 * @flow
 */
import { Dispatch } from 'redux';
import * as actionTypes from './actiontype';

/**
 * This action is used for setting Auth Key.
 * @param {string} key
 * @param {Object} val
 */
export const setAuthKey = (key: string, val: Object) => ({
  type: actionTypes.SMX_AUTH,
  payload: {
    [key]: val
  }
});

/**
 * This action is used for getting Auth Key.
 * @param {string} key
 */
export const getAuthKey = (key: string) => (dispatch: Dispatch, getState: Function) => {
  const state = getState();
  try {
    return state.Authorization[key];
  } catch (e) {
    console.log('Err', e);
  }
  return null;
};
