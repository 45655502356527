/**
 * Nike application
 * @author {Cognizant Technology Solutions}
 */
/* eslint-disable no-param-reassign */
/* eslint-disable symbol-description */
/* eslint-disable no-underscore-dangle */
import { OktaAuth } from '@okta/okta-auth-js';
import '@okta/okta-auth-js/polyfill';

const _okta = Symbol('okta');
const config = {
  qa: {
    issuer: process.env.REACT_APP_SMX_OKTA,
    authorizeUrl: `${process.env.REACT_APP_SMX_OKTA}/v1/authorize`,
    tokenUrl: `${process.env.REACT_APP_SMX_OKTA}/v1/token`
  },
  prod: {
    issuer: process.env.REACT_APP_SMX_OKTA,
    authorizeUrl: `${process.env.REACT_APP_SMX_OKTA}/v1/authorize`,
    tokenUrl: `${process.env.REACT_APP_SMX_OKTA}/v1/token`
  }
};

async function httpRequestClient(method, url, args) {
  if (
    url.includes('.well-known/openid-configuration')
    || url.includes('token')
  ) {
    args.withCredentials = false;
    delete args.headers['X-Okta-User-Agent-Extended'];
  }

  if (args.data) {
    args.body = args.data;
    delete args.data;
  }

  const response = await fetch(url, { method, ...args });
  const json = await response.json();
  return { responseText: json };
}

export default class SMxAuth {
  constructor({
    clientId, redirectUri, qa = false, storage = 'localStorage'
  }) {
    const environmentConfig = qa ? config.qa : config.prod;

    this[_okta] = new OktaAuth({
      clientId,
      redirectUri,
      httpRequestClient,
      issuer: environmentConfig.issuer,
      authorizeUrl: environmentConfig.authorizeUrl,
      tokenUrl: environmentConfig.tokenUrl,
      tokenManager: {
        storage
      }
    });
  }

  async initialize() {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    if (searchParams.get('code')) {
      const { tokens } = await this[_okta].token.parseFromUrl();
      this[_okta].tokenManager.add('idToken', tokens.idToken);
      this[_okta].tokenManager.add('accessToken', tokens.accessToken);
    }
  }

  login = (authorizeOptions = {}) => {
    this[_okta].token.getWithRedirect({
      ...authorizeOptions,
      scopes: [
        'openid', 'email', 'profile', 'legacy_username', 'iam.okta.factorygroups.read'
      ]
    }).then((response) => {
      console.log('Step 2 ===>', response);
    });
  };

  logout = () => {
    this[_okta].tokenManager.clear();
  };

  getAccessToken = async () => this[_okta].tokenManager.get('accessToken');

  getIdToken = async () => this[_okta].tokenManager.get('idToken');

  isLoggedIn = async () => {
    const idToken = await this.getIdToken();
    return idToken ? this[_okta].token.verify(idToken, {}) : false;
  };
}
