/**
 * This file is being used as reducer for UpdateTable
 * @exports UpdateTableData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  updateTableData: [],
  updateTableUpdateStatus: false,
  filterData: [],
  updateTableAllFilterData: []
};

const UpdateTableData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_UPDATE_TABLE_DATA: {
      return {
        ...state,
        updateTableData: action.payload
      };
    } case actionTypes.SET_UPDATE_STATUS_UPDATE_TABLE: {
      return {
        ...state,
        updateTableUpdateStatus: action.payload
      };
    } case actionTypes.GET_DESTINATION_MASTER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    } case actionTypes.GET_DESTINATION_MASTER_ALL_FILTER_DATA: {
      return {
        ...state,
        updateTableAllFilterData: action.payload
      };
    } case actionTypes.RESET_UPDATE_TABLE_ALL_DATA: {
      return {
        ...state,
        updateTableData: [],
        updateTableUpdateStatus: false,
        filterData: [],
        updateTableAllFilterData: []
      };
    }
    default:
      return state;
  }
};

export default UpdateTableData;
