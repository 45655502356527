/**
 * This is a functional component which is being
 * used for OKTA_AUTHORISED LOGIN.
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React, { useEffect, useState, createContext } from 'react';
import SMXAuth from '../../authorizations/SMXAuth';
import imagesPath from '../../constants/imagesPath';
import NikeSpinner from '../../nikeCustomComponents/nikeSpinner';
import NotificationDialog from '../NotificationDialog';
import defaultText from '../../constants/defaultText';
import env from '../../env.json';
import App from '../../App';
import './styles.scss';

const SessionContext = createContext<any>('');

interface Props {
  setKey: Function;
  getKey: Function;
}

export function WithAuthorization(AppComponent: Function, props: Props) {
  const { setKey, getKey } = props;
  const AuthServer = !!(
    env.APISource === 'DEV' || env.APISource === 'NONPROD' || env.APISource === 'PROD'
  );
  const SMXAuthClient = new SMXAuth({
    clientId: process.env.REACT_APP_SMX_CLIENT_ID,
    qa: AuthServer,
    storage: {
      getItem: (key) => getKey(key),
      setItem: (key, value) => setKey(key, value)
    },
    redirectUri: window.location.href
  });

  return () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [email, setEmail] = useState(null);
    /**
     * This function returns the appropriate component for
     * the login status.
     * renders the component passed only if it is logged in,
     * and shows an error dialog on failure. and a spinner on intermediate state.
     */

    const getComponent = () => {
      if (isLoggedIn === null) {
        return (
          <NikeSpinner className="loginSpinner" open={isLoggedIn === null}>
            {defaultText.authReq}
          </NikeSpinner>
        );
      }
      if (!isLoggedIn) {
        return (
          <NotificationDialog
            open
            className="loginNotification"
            image={imagesPath.nikeLogo}
            title={defaultText.authFailed}
            description={defaultText.authMsg}
          />
        );
      }
      return (
        <SessionContext.Provider value={email}>
          <AppComponent session={{ email, isLoggedIn }} />
        </SessionContext.Provider>
      );
    };

    useEffect(() => {
      SMXAuthClient.initialize()
        .then(async () => {
          /** Otherwise, check if the app already has a valid token
          * If so, get the id token from storage and set the component state */
          const isAlreadyLoggedIn = await SMXAuthClient.isLoggedIn();
          if (isAlreadyLoggedIn) {
            const idToken = await SMXAuthClient.getIdToken();
            console.log('idToken', idToken);
            setIsLoggedIn(true);
            setEmail(idToken.claims.email);
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
        });

      if (window.location.search.includes('error')) {
        setIsLoggedIn(false);
      } else if (!window.location.search.includes('code=')) {
        if (isLoggedIn === null) {
          SMXAuthClient.login();
        }
      }
    }, []);

    return (getComponent());
  };
}

export default function AuthWrapper(props: Props) {
  const AuthorizedApp = WithAuthorization(App, props);

  return (<AuthorizedApp />);
}
