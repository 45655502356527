/**
 * This file is being used as reducer for MaterialExplosion
 * @exports MaterialExplosionData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  viewMaterialExplosionAllFilterData: []
};

const ViewMaterialExplosionData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_VIEW_MATERIAL_EXPLOSION_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_VIEW_MATERIAL_EXPLOSION_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_VIEW_MATERIAL_EXPLOSION_ALL_FILTER_DATA: {
      return {
        ...state,
        viewMaterialExplosionAllFilterData: action.payload
      };
    }
    case actionTypes.RESET_VIEW_MATERIAL_EXPLOSION_DATA: {
      return {
        ...state,
        filterData: [],
        tableData: [],
        viewMaterialExplosionAllFilterData: []
      };
    }

    default:
      return state;
  }
};

export default ViewMaterialExplosionData;
