/**
 * Date of Creation: June, 2021
 * Description : This file defines NikeHeader.
 *  Its function is to toggle a menu or navigation bar between being
 *  collapsed behind the button or displayed on the screen. A combination
 *  of Icon and Name has been used with nested properties. Once a user clicks
 *  on NikeHeader panel, a NikeHeader will open with details and link to the page referred.
 * @exports Header
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Tooltip
} from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import defaultText from '../../constants/defaultText';
import imagesPath from '../../constants/imagesPath';
import './headerStyles.scss';
import appConfig from '../../appConfig';

interface Props {
  menuListOptions: Object;
  getUserDetails: Function;
  userDetails: Object;
  getMenuList: () => {};
}

const NikeHeader = (props: Props) => {
  const {
    menuListOptions, getMenuList, userDetails
  } = props;
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  /**
   * This Use effect is for fetching Menu list
  */
  useEffect(() => {
    getMenuList();
  }, []);

  const history = useHistory();
  history.listen(({ pathname }) => {
    const trimmedPath = pathname.split('/').filter(Boolean).join('/');
    const pathWithForwardSlash = trimmedPath.length > 0 ? `/${trimmedPath}` : '/';
    setCurrentRoute(pathWithForwardSlash);
  });

  const changeRoute = (link) => {
    console.log(currentRoute);
    history.push(link);
  };

  function TopHeader() {
    return (
      <div className="nikeTopHeaderContainer">
        <IconButton onClick={() => { changeRoute('/'); window.location.reload(); }} className="nikeLogoBtn">
          <img
            src={imagesPath.smxLogoWhite.src}
            alt={imagesPath.smxLogoWhite.alt}
            width="67px"
            height="auto"
          />
        </IconButton>
        <div className="nikeHeaderTitle">
          <p>{defaultText.smx_title}</p>
        </div>
        <div className="nikeTopHeaderContainer">
          <IconButton onClick={() => { window.open(appConfig.guideLink, '_blank'); }} style={{ marginRight: 10 }}>
            <HelpOutlineRounded style={{ height: 28, width: 28, color: 'white' }} />
          </IconButton>
          <Tooltip title={userDetails.name} className="userBtnTooltip" arrow>
            {/* <IconButton className="nikeUserBtn" onClick={() => changeRoute('/profile')}> */}
            <img src={imagesPath.userIcon.src} alt={imagesPath.userIcon.alt} />
            {/* </IconButton> */}
          </Tooltip>
        </div>
      </div>
    );
  }

  function HeaderSubMenu(subMenuProps: Object) {
    const { open, setOpen, subMenu } = subMenuProps;
    if (open && subMenu) {
      return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className="headerMenuBarSubmenu">
              {subMenu.map((subMenuItem, index) => (
                <ListItem
                  // eslint-disable-next-line
                  key={index.toString()}
                  button
                  className="subMenuItem"
                  onClick={() => changeRoute(subMenuItem.link)}
                >
                  {/* <Tooltip title={subMenuItem.name} arrow> */}
                  <ListItemText primary={subMenuItem.name} />
                  {/* </Tooltip> */}
                </ListItem>
              ))}
            </List>
          </Collapse>
        </ClickAwayListener>
      );
    }
    return null;
  }

  function HeaderMenu(menuItemProps: Object) {
    const { item, itemKey: key } = menuItemProps;
    const [isSubMenuVisible, setIsSubMenuVisible] = React.useState(false);
    const getHandlers = () => {
      const handlers = {};
      if (item.subMenu) {
        handlers.onClick = () => {
          setIsSubMenuVisible(!isSubMenuVisible);
        };
      } else {
        handlers.onClick = (e) => {
          if (item.link) {
            e.preventDefault();
            e.stopPropagation();
            changeRoute(item.link);
          }
        };
      }
      return handlers;
    };

    const getCollapseMenuIcon = () => {
      if (isSubMenuVisible) {
        return <ArrowDropUp className="menuCollapseIcon" />;
      }
      return <ArrowDropDown className="menuCollapseIcon" />;
    };

    return (
      <div className="menuItemContainer">
        {/* <Tooltip title={item.name} arrow> */}
        <Button
          // eslint-disable-next-line
          key={key}
          className="menuItem"
          {...getHandlers()}
          data-testid="listItem"
        // selected={
        //   (((item.link || '').length > 1)
        //     ? currentRoute.includes(item.link)
        //     : item.link === currentRoute)
        //   || (item.relatedLinks || []).includes(currentRoute)
        // }
        // classes={{ selected: 'hambergerCollapsedMenuItemsSelected' }}
        >
          {item.name}
          {item.subMenu ? getCollapseMenuIcon() : null}
        </Button>
        {/* </Tooltip> */}
        <div className="subMenuItemContainer">
          <HeaderSubMenu
            subMenu={item.subMenu}
            open={isSubMenuVisible}
            setOpen={setIsSubMenuVisible}
          />
        </div>
      </div>
    );
  }

  function BottomHeader() {
    const revisedMenuOptions = [];
    menuListOptions.forEach((item) => {
      userDetails.group.forEach((item2) => {
        if (
          item.group
          && userDetails.group.length > 0
          && item.group.includes(item2)
          && revisedMenuOptions.indexOf(item) === -1
        ) {
          revisedMenuOptions.push(item);
        }
      });
    });
    return (
      <div className="nikeBottomHeader">
        <div className="nikeNavBar">
          {revisedMenuOptions.map((item, index) => (
            <HeaderMenu
              // eslint-disable-next-line
              key={index.toString()}
              itemKey={index.toString()}
              item={item}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="nikeCustomHeader">
      <TopHeader />
      <BottomHeader />
    </div>
  );
};

NikeHeader.propTypes = {
  menuListOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    relatedLinks: PropTypes.arrayOf(PropTypes.string),
    subMenu: PropTypes.arrayOf({
      link: PropTypes.string,
      name: PropTypes.string
    }),
    group: PropTypes.arrayOf(PropTypes.string)
  })).isRequired,
  getMenuList: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    group: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default NikeHeader;
