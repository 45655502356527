/**
 * This file is being used as reducer for GeoOrder
 * @exports GeoOrderData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  geoOrderSaved: { status: false, singleCick: false },
  geoOrderDeleted: false,
  geoOrderValidated: { status: false, singleCick: false },
  geoOrderUploaded: false,
  uploadErrorMessage: '',
  filterData: [],
  tableData: [],
  geoOrderAllFilterData: [],
  geoTemplateUploaded: false
};

const GeoOrderData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_GEO_ORDER_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_GEO_VIEW_ORDER_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_GEO_ORDER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.SET_GEO_ORDER_SAVE_STATUS: {
      return {
        ...state,
        geoOrderSaved: action.payload
      };
    }
    case actionTypes.SET_GEO_ORDER_UPLOAD_ERROR_MESSAGE: {
      return {
        ...state,
        uploadErrorMessage: action.payload
      };
    }
    case actionTypes.SET_GEO_ORDER_DELETE_STATUS: {
      return {
        ...state,
        geoOrderDeleted: action.payload
      };
    }
    case actionTypes.SET_GEO_ORDER_UPLOAD_STATUS: {
      return {
        ...state,
        geoOrderUploaded: action.payload
      };
    }
    case actionTypes.SET_GEO_TEMPLATE_UPLOAD_STATUS: {
      return {
        ...state,
        geoTemplateUploaded: action.payload
      };
    }
    case actionTypes.GET_GEO_ORDER_ALL_FILTER_DATA: {
      return {
        ...state,
        geoOrderAllFilterData: action.payload
      };
    }
    case actionTypes.SET_GEO_ORDER_VALIDATION_STATUS: {
      return {
        ...state,
        geoOrderValidated: action.payload
      };
    }
    case actionTypes.RESET_GEO_ORDER_ALL_DATA: {
      return {
        ...state,
        geoOrderSaved: { status: false, singleCick: false },
        geoOrderDeleted: false,
        geoOrderValidated: { status: false, singleCick: false },
        geoOrderUploaded: false,
        uploadErrorMessage: '',
        userDetails: {},
        filterData: [],
        tableData: [],
        geoOrderAllFilterData: [],
        geoTemplateUploaded: false
      };
    }
    default:
      return state;
  }
};

export default GeoOrderData;
