/**
 * Nike application
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import AppAuthorization from './containers/AppAuthorization';

const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppAuthorization />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
}
/** If you want your app to work offline and load faster, you can change
* unregister() to register() below. Note this comes with some pitfalls.
* Learn more about service workers: https://bit.ly/CRA-PWA */
serviceWorker.unregister();
