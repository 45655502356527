/**
* Redux Container Component for Authorization
* @exports ConnectedComponent
* @author {Cognizant Technology Solutions}
* @flow
*/
import { connect, Dispatch } from 'react-redux';
import { setAuthKey, getAuthKey } from '../../actions/authAction';
import Auth from '../../components/AppAuthorization';

export const mapStateToProps = () => ({});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  setKey: (key: string, val: Object) => dispatch(setAuthKey(key, val)),
  getKey: (key: string) => dispatch(getAuthKey(key))
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
