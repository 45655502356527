/**
 * This file is being used as reducer for OrderStatusReport
 * @exports OrderStatusReportData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  orderStatusReportData: []
};

const OrderStatusReportData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_STATUS_REPORT_DATA: {
      return {
        ...state,
        orderStatusReportData: action.payload
      };
    }
    case actionTypes.ORDER_STATUS_REPORT_SPINNER: {
      return {
        ...state,
        spinner: action.payload
      };
    }
    case actionTypes.GET_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    default:
      return state;
  }
};

export default OrderStatusReportData;
