/**
 * This file is being used as reducer for mass update
 * @exports MassUpdate Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  spinnerText: {},
  opemSpinner: false,
  massUpdated: false,
  snackbar: {},
  openSnackbar: false,
  seasonOrderData: {}
};

const CommonData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_SPINNER: {
      return {
        ...state,
        spinnerText: action.payload,
        openSpinner: true
      };
    }
    case actionTypes.SET_SPINNER: {
      return {
        ...state,
        openSpinner: action.payload
      };
    }
    case actionTypes.SET_SNACKBAR: {
      return {
        ...state,
        snackbar: action.payload,
        openSnackbar: true
      };
    }
    case actionTypes.CLOSE_SNACKBAR: {
      return {
        ...state,
        openSnackbar: false
      };
    }
    case actionTypes.SET_MASS_UPDATE_STATUS: {
      return {
        ...state,
        massUpdated: action.payload
      };
    }
    case actionTypes.GET_SEASON_ORDER_DATA: {
      return {
        ...state,
        seasonOrderData: action.payload
      };
    }
    default:
      return state;
  }
};

export default CommonData;
