/**
 * App Configuration file
 * @author {Cognizant Technology Solutions}
 */

const gso = 'App.smx.gso';
const creators = 'App.smx.creators';
const dec = 'App.smx.dec';
const factory = 'App.smx.factory';
const extFactory = 'EXT.App.smx.factory';
const pcc = 'App.smx.pcc';
const mfg = 'App.smx.materials';
const readOnly = 'App.smx.ReadOnly';
const extReadOnly = 'EXT.App.smx.ReadOnly';
const developers = 'App.smx.Developer';
const superUser = 'App.SMx.NikeSalesmanSampleProcess';

const appConfig = {
  fetchLimit: 500,
  fetchOffset: 0,
  rowsPerPageOptions: [500, 250, 50],
  textLengthInfo: 50,
  dateFormat: 'MM/dd/yyyy',
  gso,
  creators,
  dec,
  factory,
  extFactory,
  pcc,
  mfg,
  readOnly,
  extReadOnly,
  developers,
  superUser,
  /* list of user groups - not in use */
  userGroups: [gso, creators, dec, factory, pcc, mfg, superUser, readOnly, developers],
  externalUserGroups: [extFactory, extReadOnly],
  userGroupPrecedence: [gso, creators, dec, factory, pcc, mfg, readOnly],
  /* Access user group as per the module */
  nonSalesOrderGroups: [gso, creators, readOnly, superUser, developers, extReadOnly],
  manageOrderGroups: [gso, readOnly, superUser, developers, extReadOnly],
  createOrderGroup: [gso, readOnly, superUser, developers, extReadOnly],
  publishOrderGroups: [gso, readOnly, superUser, developers, extReadOnly],
  reportsGroups: [
    gso, creators, dec, factory, pcc, mfg, readOnly, superUser, developers, extFactory, extReadOnly
  ],
  manualTableGroups: [
    gso, superUser, developers
  ],

  reportOption: [
    // { text: 'Order Status', value: 'order_status' },
    { text: 'OSTR Report', value: 'ostr_report' }
    // { text: 'Smart Sheet', value: 'smart_sheet' }
  ],

  tableOption: [
    { text: 'Additional Exception', value: 'additional_exception' },
    { text: 'Destination Master', value: 'destination_master' },
    { text: 'Factory Exception', value: 'factory_exception' },
    { text: 'Transportation Mode', value: 'transportation_mode' },
    { text: 'Order Type', value: 'order_type' },
    { text: 'Region Master', value: 'region_master' },
    { text: 'Season Master', value: 'season_master' },
    { text: 'Sister Factory', value: 'sister_factory' },
    { text: 'Size Exception', value: 'size_exception' }
  ],

  guideLink: 'https://nike.ent.box.com/file/1357139245340'
};

export default appConfig;
