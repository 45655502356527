/**
 * This file is being used as reducer for CaptureOrder
 * @exports ViewOrderData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: []
};

const ViewOrderData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_VIEW_ORDER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.RESET_VIEW_ORDER_DATA: {
      return {
        ...state,
        filterData: []
      };
    }
    default:
      return state;
  }
};

export default ViewOrderData;
