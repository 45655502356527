/**
 * This file is being used as reducer for SubmitToSAP
 * @exports SubmitToSAPData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  submitToSAPAllFilterData: [],
  submitToSAPValidated: false,
  submitToSAPsubmited: false,
  submitToSAPTemplateUploaded: false
};

const SubmitToSAPData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_SUBMIT_TO_SAP_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_SUBMIT_TO_SAP_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_VIEW_SUBMIT_TO_SAP_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_SUBMIT_TO_SAP_ALL_FILTER_DATA: {
      return {
        ...state,
        submitToSAPAllFilterData: action.payload
      };
    }
    case actionTypes.SET_SUBMIT_TO_SAP_VALIDATION_STATUS: {
      return {
        ...state,
        submitToSAPValidated: action.payload
      };
    }
    case actionTypes.SET_SUBMIT_TO_SAP_SUBMIT_STATUS: {
      return {
        ...state,
        submitToSAPsubmited: action.payload
      };
    }
    case actionTypes.SET_SUBMIT_TO_SAP_TEMPLATE_UPLOAD_STATUS: {
      return {
        ...state,
        submitToSAPTemplateUploaded: action.payload
      };
    }
    case actionTypes.RESET_SUBMIT_TO_SAP_ALL_DATA: {
      return {
        ...state,
        filterData: [],
        tableData: [],
        submitToSAPAllFilterData: [],
        submitToSAPTemplateUploaded: false,
        submitToSAPValidated: { status: false, singleCick: false },
        submitToSAPsubmited: { status: false, singleCick: false }
      };
    }
    default:
      return state;
  }
};

export default SubmitToSAPData;
