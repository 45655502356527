/**
 * File contains static string error, warnings or info messages.
 * The file can be imported directly wherever required.
 * @flow
 * @author {Cognizant Technology Solution}
 */
const defaultText = {
  no_error: 'NO_ERROR',
  MULTIPLE_FOB_FOUND: 'MULTIPLE_FOB_FOUND',
  smx_title: 'Samples Management eXcellence',
  error_txt: 'ERROR',
  error_reason: 'Something went wrong',
  error: 'ERROR',
  authFailed: 'Authorization Failed',
  authMsg: 'You don\'t have permission to view this application. Please contact the SMX system admininistrator.',
  authReq: 'Please wait while we authenticate your request.',
  appErrorDescription: 'Something went wrong',
  snackbarErrorMessage: 'Internal Server Error! Please try after sometime.',
  productLabel: 'Product',
  bomLabel: 'BOM',
  linePlanLabel: 'SKU',
  fobLabel: 'FOB',
  sourcePlanLabel: 'Source Plan',
  orderSentToSapLabel: 'Orders sent to SAP',
  poCreateLabel: 'POs Created',
  poRejectedLabel: 'POs Rejected',
  poPendingLabel: 'POs Pending',
  orderStatusLabel: 'Order Status',
  captureOrderLabel: 'Capture Order',
  viewOrderLabel: 'View/Update/Download Order',
  viewGGPOrderLabel: 'View/Update GGP Order',
  uploadOrderLabel: 'Upload Order',
  materialExplosionLabel: 'Material Explosion',
  SSPOLabel: 'Samples PO',
  viewSSPOLabel: 'View Samples PO',
  sampleFlaggingLabel: 'Sample Flagging',
  orderFileUploadLabel: 'Order File Upload',
  seasonLabel: 'Season Year',
  userDestinationDetailsLabel: 'User & Destination details',
  requestedByLabel: 'Requested By',
  orderTypeLabel: 'Order Type *',
  tableTypeLabel: 'Table Type *',
  compLabel: 'Comp#',
  shipToLabel: 'Ship to#',
  recipientName: 'Recipient Name (ShipTo - StockCat - Country)',
  uploadOrderRecipientName: 'Recipient Name (ShipTo - StockCat - Country)',
  firstName: 'First Name',
  lastName: 'Last Name',
  captureOrderBodyLabelOne: 'Please fill the details above and',
  captureOrderBodyLabelTwo: 'Validate the Shipment details by clicking on ',
  captureOrderBodyLabelThree: 'When you’re done, click on ',
  captureOrderBodyLabelFour: ' to Save & Continue',
  geoOrderBodyLabelFour: ' to Continue',
  viewMaterialExplosionLabel: 'View Material Explosion Details',
  nameError: 'You have updated the recipient name. Global Sample Ops team will be notified on continuing with the order.',
  filterBodyText: 'Please apply Filters above to view the results here',
  uploadBodyText: 'Upload / Drag and drop Order file',
  noResultLabel: 'No Results Found! Are you sure the Filters are correct?',
  uploadFileError: 'The uploaded file is not of the expected format',
  filterLabel: 'Filters',
  filterDescription: 'You have not applied any filter. Click on the Filter icon above to add filters.',
  Confirmation: 'Confirmation',
  oetcDate: 'OETC Date',
  ogacDate: 'OGAC Date',
  geoOrderLabel: 'GEO ORDER',
  geoOrderTypeSelection: 'Geo & Order Type selection',
  viewGeoOrderLabel: 'View/Update GEO ORDER',
  OrderTypeSelection: 'Select Order Type',
  geoLabel: 'Geo',
  materialExplosionOrderType: 'Order Type',
  quantityFileText: 'Upload Quantity file',
  uploadQuantityFileText: 'Upload / Drag and drop Quantity file',
  changeFileText: 'Upload Change file',
  uploadChangeFileText: 'Upload / Drag and drop Change file',
  downloadTemplateText: 'Download Template',
  oopsText: 'Oops!',
  unexpectedFormatText: 'Internal server error, please try again later.',
  downloadEmailDetailMsg: 'Once data is ready for download you will notified by an email',
  downloadinAllmsg: 'Downloading all data',
  waitTextSpinner: 'Please Wait...',
  fetchResult: 'Fetching your results',
  dashBoardSpinnerText: 'Fetching your Dashboard information.',
  sdrSpinnerTxt: 'Fetching your SDR information',
  captureOrderSpinnerText: 'Fetching your Capture Order information.',
  sisterFactorySpinnerText: 'Fetching your Sister Factory Update information.',
  uploadOrderSpinnerText: 'Fetching your information for upload',
  geoOrderSpinnerText: 'Fetching your Geo Order information.',
  materialExplosionSpinnerText: 'Fetching your Material Explosion information.',
  viewOrderDetailsLabel: 'View Order Details',
  orderDetailsLabel: 'Order Details',
  shareOrderLabel: 'SHARE ORDER',
  shareOrderTypeSelection: 'View & Share',
  submitToSAPLabel: 'SUBMIT TO SAP',
  sisterFactoryLabel: 'SISTER FACTORY UPDATE',
  sisterFactoryTypeSelection: 'View and Update',
  factoryRankOneLabel: 'Factory Rank One',
  addToQtyLabel: 'Add to Qty',
  useAllItems: 'Use All Items',
  validateFOBLabel: 'VALIDATE FOB',
  validateFOBselection: 'Verify and Validate',
  validateSaveFOBselection: 'Verify and Save',
  SubmitToSAPLabel: 'SUBMIT TO SAP',
  SAPSelection: 'Verify and Validate',
  viewSAPSelection: 'View/Update Submit To SAP',
  ggpOrderLabel: 'GGP ORDER',
  ggpOrderQuantitySelection: 'Quantity Selection',
  ggpOrderFileUpload: 'Order File Upload',
  orderStatusReportLabel: 'ORDER STATUS Report',
  orderStatusReportText: 'View and Download',
  sizeCheckReportLabel: 'SIZE CHECK REPORT',
  sizeCheckReportText: 'View and Send Email',
  validateFOBSpinnerText: 'Fetching your Validate FOB information.',
  submitToSAPSpinnerText: 'Fetching your Submit To SAP information.',
  shareOrderSpinnerText: 'Fetching your Share Order information.',
  ggpOrderSpinnerText: 'Fetching your GGP Order information.',
  Success: 'Success!',
  captureOrderValidationMessage: 'Records are successfully validated! Please click on Submit to proceed with order submission.',
  emptyFieldAlert: 'The input field cannot be empty',
  updateShipToConfirmationText: 'Clicking on ‘Yes’ will notify updated Recipient name for review. Are you sure you want to proceed?',
  sizeGridSpinner: 'Fetching Size Grid',
  success: 'Success',
  uploadSuccess: 'File has been uploaded successfully.',
  finalSubmitDesc: 'All records have been submitted successfully.',
  showOnlyInvalidRecords: 'Show only Invalid records',
  tryAgiainMessage: 'Some internal error occured, Please try again.',
  reportTypeLabel: 'Report Type',
  createTimestamp: 'Order Created Date',

  /** common to capture and validate */
  sampleFlaggingData: 'Sample Flagging Records',
  submittingData: 'Submitting Records',
  massUpdatingData: 'Mass Updating Records',
  updatingQuantity: 'Updating Quantity',
  updatingRecords: 'Updating Records',
  /** validation texts */
  assigningSourceMsg: 'Assigning Source',
  falloutReportMsg: 'Checking Fallout Report Data',
  validatingSizesMsg: 'Validating sizes',
  validatingFactoryMsg: 'Validating factory',
  additionalValidationMsg: 'Checking for additional validation',
  updateTableLabel: 'Update Manual Table',
  updateTableText: 'Update data for the Manual Entry Table',
  uploadMessage: 'File has been uploaded successfully and being processed. Once completed, we will email you.',
  validationMessage: 'Validation is in progress. Once completed, we will email you.',
  submitMessage: 'Records saving and submition is in progress. Once completed, we will email you.',
  POsubmitMessage: 'PO submission is in progress. Once completed, we will email you.',
  materialSavingMessage: 'Material Explosion Data Saving is in progress. Once completed, we will email you.',
  /** tooltip texts */
  deleteToolTipText: 'For deleting FALLOUT records, please toggle those',
  massUpdateToolTipText: 'For individual record/s mass update, need to toggle that record/s',
  shareToolTipText: 'Click this button to Share toggled records only',
  sendToolTipText: 'Click this button to Send all records',
  emailToolTipText: 'click this button to Email Invalid toggled records only',
  downloadToolTipText: 'Download will record for whatever pages you have traversed till now',
  validateToolTipText: 'Click this button to validate current page and once done, it will prompt for next page',
  submitToolTipText: 'Click this button to submit current page and once done, it will prompt for next page',
  materialExplodeToolTipText: 'Click this button to material explosion for all records',
  updateToolTipText: 'Click this button to update all records',
  uploadTemplateToolTipText: 'Click this button to upload template',
  saveToolTipText: 'Click this button to save current page and once done, it will prompt for next page',
  flagSizeToolTipText: 'Click this button to sample flag/size selection of current page for toogled records and for next page, click next button in the table',
  downloadFactoryToolTipText: 'Download All records before you Save',
  download: 'Download',
  close: 'Close'
};

export default defaultText;
