/**
 * Reducer for auth
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import { SMX_AUTH } from '../actions/actiontype';

const initialState = {};

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case SMX_AUTH: {
      let parsedToken = null;
      if (action.payload['okta-token-storage']) {
        parsedToken = JSON.parse(action.payload['okta-token-storage']);
      }

      const claims = parsedToken
        ? parsedToken
        && parsedToken.idToken
        && parsedToken.idToken.claims
        : {};
      const oktaUserGroupMapping = claims.groups;
      const userDetails = {
        group: oktaUserGroupMapping,
        email: claims.email,
        name: claims.name
      };

      return {
        ...state,
        ...action.payload,
        parsedToken,
        userDetails
      };
    }
    default:
      return state;
  }
};
