/**
 * This file is being used as reducer for ShareOrder
 * @exports ShareOrderData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  filterData: [],
  tableData: [],
  emailSent: false,
  shareOrderAllFilterData: {},
  allEmailSent: false
};

const ShareOrderData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_SHARE_ORDER_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.GET_SHARE_ORDER_EMAIL_STATUS: {
      return {
        ...state,
        emailSent: action.payload
      };
    }
    case actionTypes.GET_SHARE_ORDER_ALL_EMAIL_STATUS: {
      return {
        ...state,
        allEmailSent: action.payload
      };
    }
    case actionTypes.GET_SHARE_ORDER_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_MATERIAL_EXPLOSION_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_SHARE_ORDER_ALL_FILTER_DATA: {
      return {
        ...state,
        shareOrderAllFilterData: action.payload
      };
    }
    case actionTypes.RESET_SHARE_ORDER_DATA: {
      return {
        ...state,
        filterData: [],
        tableData: [],
        emailSent: false,
        shareOrderAllFilterData: {},
        allEmailSent: false
      };
    }

    default:
      return state;
  }
};

export default ShareOrderData;
