/**
 * nike custom component - NikeButton
 * default color theme will be primary from MUI.
 * can be customized based on the variant
 * @exports NikeButton
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import React from 'react';
import './nikeButtonstyles.scss';
import { Button, Tooltip } from '@material-ui/core';

interface Props {
  color?: string;
  buttonText?: Object;
  className?: string;
  variant?: string;
  onClick?: Function;
}

export default function NikeButton(props: Props) {
  const {
    color,
    buttonText,
    variant,
    onClick,
    className,
    openToolTip,
    toolTipTitle,
    ...other
  } = props;

  const classname = `nikeButton ${className || ''}`;
  const buttonTheme = color || 'primary';
  const showToolTip = openToolTip || undefined;
  const showToolTipTitle = toolTipTitle || '';

  return (

    <Tooltip title={showToolTipTitle} arrow open={showToolTip}>
      <Button
        color={buttonTheme}
        variant={variant}
        className={classname}
        onClick={onClick}
        {...other}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
}
