/**
 * Custom nike spinner component
 * Description: This file defines the nikeSpinner
 * Its function is to appear as a buffer during loading of data in a page
 * @exports NikeSpinner of @type {ReactComponent}
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React from 'react';
import { DialogContent, Dialog } from '@material-ui/core';
import './spinnerStyles.scss';
import imagesPath from '../../constants/imagesPath';

interface Props {
  onClose?: Function;
  open?: boolean;
  children?: any;
  className?: string;
}

function NikeSpinner(props: Props) {
  const {
    onClose, open, children, className
  } = props;
  return (
    <Dialog
      classes={{
        paper: 'spinner-dialog'
      }}
      className={className}
      onClose={onClose}
      disableEscapeKeyDown
      maxWidth="lg"
      open={open}
    >
      <div className="nike-spinner-wrapper"><img src={imagesPath.spinner.src} alt={imagesPath.spinner.alt} /></div>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default NikeSpinner;
