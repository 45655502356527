/**
 * This file is being used as reducer for ValidateFOB
 * @exports ValidateFOBData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  validateFOBSaved: false,
  validateFOBEmailSent: false,
  validateFOBValidated: false,
  filterData: [],
  tableData: [],
  validateFOBAllFilterData: []
};

const ValidateFOBData = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.GET_VALIDATE_FOB_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_VALIDATE_FOB_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    case actionTypes.SET_VALIDATE_FOB_SAVE_STATUS: {
      return {
        ...state,
        validateFOBSaved: action.payload
      };
    }
    case actionTypes.GET_VALIDATE_FOB_ALL_FILTER_DATA: {
      return {
        ...state,
        validateFOBAllFilterData: action.payload
      };
    }
    case actionTypes.SET_VALIDATE_FOB_VALIDATION_STATUS: {
      return {
        ...state,
        validateFOBValidated: action.payload
      };
    }
    case actionTypes.SET_VALIDATE_FOB_EMAIL_STATUS: {
      return {
        ...state,
        validateFOBEmailSent: action.payload
      };
    }
    case actionTypes.RESET_VALIDATE_FOB_ALL_DATA: {
      return {
        ...state,
        validateFOBSaved: false,
        validateFOBEmailSent: false,
        validateFOBValidated: false,
        filterData: [],
        tableData: [],
        validateFOBAllFilterData: []
      };
    }
    default:
      return state;
  }
};

export default ValidateFOBData;
