/**
 * This component can be used for all the notification style
 * dialogs.
 * @exports NotificationDialog
 * @author {Cognizant Technology Solution}
 * @flow
 */
import React from 'react';
import { uniqueId } from 'lodash';
import NikeButton from '../../nikeCustomComponents/nikeButton';
import NikeDialog from '../../nikeCustomComponents/nikeDialog';
import './styles.scss';

  interface Action {
    buttonText: string;
    onClick: Function;
  }

  interface Props {
    image: {
      src: Object;
      alt: string;
    };
    title?: string;
    description?: string;
    actions: Array<Action>;
    open: boolean;
    onEscapeKeyDown?: Function;
    onBackdropClick?: Function;
    className?: string;
  }

export default function NotificationDialog(props: Props) {
  const {
    image,
    title,
    description,
    actions,
    open,
    onEscapeKeyDown,
    onBackdropClick,
    className
  } = props;
  /**
     * This method is used as handler for rendering
     * the notification dialog content.
     */
  const dialogContent = (
    <>
      {
        image && (
          <div className="imageContainer">
            <img src={image.src} alt={image.alt} />
          </div>
        )
      }
      <div className="textContainer">
        <div className="title">{title}</div>
        <span className="description">
          {description}
        </span>
      </div>
    </>
  );
  /**
      * This method is used as handler for rendering
      * the notification dialog actions like close, download etc.
      */
  const renderActions = (
    <div>
      {
          actions && actions.map((action, index) => (
            <NikeButton
              // eslint-disable-next-line
              key={index.toString()}
              buttonText={uniqueId('key_')}
              onClick={action.onClick}
              variant="rounded-black"
            />
          ))
        }
    </div>
  );

  return (
    <NikeDialog
      onBackdropClick={onBackdropClick}
      onEscapeKeyDown={onEscapeKeyDown}
      open={open}
      className={`notificationDialog ${className || ''}`}
      renderContent={dialogContent}
      renderActions={renderActions}
    />
  );
}

NotificationDialog.defaultProps = {
  actions: []
};
