/**
 * This file is being used as reducer for sizeCheckReport
 * @exports sizeCheckReportData Reducer as default
 * @author {Cognizant Technology Solutions}
 * @flow
 */
import * as actionTypes from '../actions/actiontype';

const initialState = {
  spinner: false,
  userDetails: {},
  filterData: [],
  tableData: []
};

export default (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case actionTypes.SIZE_CHECK_REPORT_SPINNER: {
      return {
        ...state,
        spinner: action.payload
      };
    }
    case actionTypes.USER_DETAILS_SIZE_CHECK_REPORT: {
      return {
        ...state,
        userDetails: action.payload
      };
    }
    case actionTypes.GET_SIZE_CHECK_REPORT_FILTER_DATA: {
      return {
        ...state,
        filterData: action.payload
      };
    }
    case actionTypes.GET_SIZE_CHECK_REPORT_TABLE_DATA: {
      return {
        ...state,
        tableData: action.payload
      };
    }
    default:
      return state;
  }
};
